import React, { useState, useEffect } from "react"
import * as queryString from "query-string";
import "../css/style.scss"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import SideMenu from "../components/side-menu"
import Hero2 from "../components/hero2"
import Section2 from "../components/section-2"
import Section3 from "../components/section-3"
import Section4 from "../components/section-4"
import Section5 from "../components/section-5"
import Section6 from "../components/section-6"
import Footer from "../components/footer"

const IndexPage = ({ location }) => {
  const [isMobile, setIsMobile] = useState('');
  const [pageType, setPageType] = useState('economy');

  const params = new URLSearchParams(location.search);
  const returnToPage = params.get("return");

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }

  }, [setIsMobile])

  return (
    <Layout>
      <Seo />
      <Header />
      <main className={`${pageType == 'economy' ? '' : 'first-class'}`}>
        <SideMenu pageType={pageType} setPageType={setPageType} />
        <div
          id="overlay" ></div>
        <Hero2 isMobile={isMobile} pageType={pageType} />
        <Section2 isMobile={isMobile} pageType={pageType} />
        <Section3 back={returnToPage} isMobile={isMobile} pageType={pageType} />
        <Section5 pageType={pageType} />
        <Section6 pageType={pageType} />
      </main>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
